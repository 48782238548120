import {useCallback, useEffect, useMemo, useState} from "react";
import DataInputField from "./dataInput_components/inputField";
import apiClient from "../hooks/apiClient";
import Loading from "./Loading";

function ScanQR(props) {

    const tg = useMemo(() => window.Telegram.WebApp, [])

    const [globalLoading, setGlobalLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [tryings, setTryings] = useState(0)

    const parseQRDate = useCallback((dateString) => {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6) - 1;
        const day = dateString.slice(6, 8);
        const hour = dateString.slice(9, 11);
        const minute = dateString.slice(11, 13);
        const second = dateString.slice(13, 15);

        return new Date(Date.UTC(year, month, day, hour, minute, second))
    }, [])


    const goToManualInput = useCallback(async() => {
        setGlobalLoading(true)
        await apiClient.post('receipts/qr_scan_error')
        tg.close()
    }, [])

    const handleTextQR = useCallback(async(qrText) => {
        console.log(qrText)
        setTryings(prevState => prevState + 1)
        if (qrText.startsWith('https://')) {
            if (tryings === 3) {
                await goToManualInput()
            }
            setErrorMessage('Вы отсканировали не тот QR код. Отсканируйте QR код в самом низу чека')
            return
        }

        let qrElements = qrText.split('&')
        if (qrElements.length !== 6) {
            if (tryings === 3) {
                await goToManualInput()
            }
            setErrorMessage('Вы отсканировали не тот QR код. ' +
                'Отсканируйте QR код на чеке из Modis. Он находится в самом низу')
            return
        }
        let qrElementsMap = {}
        for (let i in qrElements) {
            let qrElement = qrElements[i]
            let [k, v] = qrElement.split('=')
            qrElementsMap[k] = v
        }
        if (qrElementsMap.n !== '1') {
            setErrorMessage('Этот чек не подходит, т.к. не является чеком на покупку. Попробуйте другой чек')
            return
        }

        const postData = {
            FN: qrElementsMap.fn,
            FD: qrElementsMap.i,
            FP: qrElementsMap.fp,
            buying_summ: qrElementsMap.s,
            buying_date: parseQRDate(qrElementsMap.t).toISOString()
        }
        try {
            setGlobalLoading(true)
            const response = await apiClient.post('/receipts/qr', postData)
            tg.close()
            setGlobalLoading(false)
        } catch (e) {
            if (tryings === 3) {
                await goToManualInput()
            }
            setGlobalLoading(false)
            setErrorMessage(e.response.data.detail)
        }
    }, [tryings, goToManualInput, tg])

    const openScanQR = useCallback(() => {
        setErrorMessage('')
        tg.showScanQrPopup({text: 'Наведите на QR-код внизу чека'}, handleTextQR)
    }, [tg, handleTextQR])

    useEffect(() => {
        openScanQR()
    }, [])

    return (
        <>
            <div className="kassa login">
                <h1>Modis - сканирование QR чека</h1>
                <div className="notify">
                    <div className={`error ${(errorMessage.length === 0) ? 'hide' : ''}`}>{errorMessage}</div>
                </div>
                <div className="next">
                    <button
                        className={`next-button open-scan`}
                        onClick={openScanQR}
                    >
                        <span>Отсканировать QR</span>
                    </button>
                    <button
                        className={`next-button scan-error`}
                        onClick={goToManualInput}
                    >
                        <span>Проблема со сканированием</span>
                    </button>
                </div>
                <Loading isLoading={globalLoading}/>
            </div>
        </>
    )
}

export default ScanQR;
